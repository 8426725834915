import { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import backIcon from 'assets/images/back-icon.svg';
import copyIcon from 'assets/images/copy-icon-dark.svg';
import editIcon from 'assets/images/edit-icon-dark.svg';
import { truncateAddress } from 'utils/truncateAddress';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import './UserProfileMobile.scss';
import { UserServices } from 'services/UserServices';
import { DashboardContext, type IDashboardContext } from './DashboardContext';
interface Props {
  setShowUserProfile: any;
}

export const UserProfileMobile = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [selectedProfile, setSelectedProfile] = useState('');
  const [showCopiedWallet, setShowCopiedWallet] = useState(false);

  useEffect(() => {
    const getProfile = async (): Promise<void> => {
      const profile = await UserServices.getSelectedProfile();

      switch (profile) {
        case 1:
          setSelectedProfile(t('account.careful') ?? 'Careful');
          break;
        case 2:
          setSelectedProfile(t('account.balanced') ?? 'Balanced');
          break;
        case 3:
          setSelectedProfile(t('account.adventurous') ?? 'Adventurous');
          break;
        default:
          setSelectedProfile('Undefined');
      }
    };

    // call the function
    getProfile().catch(console.error);
  }, [dashboardContext.showSelectProfilePopup]);

  return (
    <div className="user-profile-mobile">
      <Container>
        <Row className="header">
          <Col xs={3}>
            <img
              src={backIcon}
              alt="Back"
              width={50}
              height={18}
              onClick={() => {
                props.setShowUserProfile(false);
              }}
            />
          </Col>
          <Col className="">
            <p className="welcome text-center">{t('account.useraccount')}</p>
          </Col>
          <Col xs={3} className="text-end"></Col>
        </Row>
        <Row className="profile-block">
          <Col>{t('account.walet')}</Col>
          <Col className="text-end">
            <div
              className="content-item"
              onClick={() => {
                navigator.clipboard.writeText(dashboardContext.account).catch(() => {});
                setShowCopiedWallet(true);
                setTimeout(() => {
                  setShowCopiedWallet(false);
                }, 3000);
              }}
            >
              <OverlayTrigger
                trigger="click"
                overlay={<Tooltip id="test">{t('account.copied')}</Tooltip>}
                placement="top"
                show={showCopiedWallet}
              >
                <div role="button">
                  <div className="d-flex">
                    <p className="flex-grow-1">{truncateAddress(dashboardContext.account)}</p>
                    <img
                      src={copyIcon}
                      alt="Copy"
                      height={13}
                      width={13}
                      style={{ marginLeft: 8 }}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <Row className="profile-block">
          <Col>{t('account.profile')}</Col>
          <Col className="text-end">
            <div
              role="button"
              onClick={() => {
                props.setShowUserProfile(false);
                dashboardContext.setShowSelectProfilePopup(true);
              }}
            >
              <div className="d-flex">
                <p className="flex-grow-1">{selectedProfile}</p>
                <img src={editIcon} alt="Copy" height={13} width={13} style={{ marginLeft: 8 }} />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="profile-block">
          <Col>{t('account.language')}</Col>
          <Col className="text-end">
            <div
              role="button"
              onClick={() => {
                dashboardContext.setShowSelectLanguage(true);
              }}
            >
              <div className="d-flex">
                <p className="flex-grow-1">
                  {i18n.language === 'en' ? t('account.en') : t('account.nl')}
                </p>
                <img src={editIcon} alt="Copy" height={13} width={13} style={{ marginLeft: 8 }} />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="profile-block">
          <Col>
            Currency
            {/* {t('account.language')} */}
          </Col>
          <Col className="text-end">
            <div
              role="button"
              onClick={() => {
                dashboardContext.setShowCurrencySwitchPopup(true);
                // dashboardContext.setShowSelectLanguage(true);
              }}
            >
              <div className="d-flex">
                <p className="flex-grow-1">
                  {dashboardContext.currencyInfo.rate === 1 ? 'Euro ' : 'US Dollar '}
                  {'(' + dashboardContext.currencyInfo.symbol + ')'}
                  {/* {i18n.language === 'en' ? t('account.en') : t('account.nl')} */}
                </p>
                <img src={editIcon} alt="Copy" height={13} width={13} style={{ marginLeft: 8 }} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="logout d-flex align-item-center"
              onClick={() => {
                dashboardContext.setShowLogout(true);
              }}
            >
              <div className="mx-auto " style={{ cursor: 'pointer' }}>
                {t('sidebar.logout').toUpperCase()}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
