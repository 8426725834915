import React from 'react';
import { Accordion } from 'react-bootstrap';
import { ContactButtons } from './ContactButtons';

import './InvestorFAQ.scss';

export const InvestorFAQ = (): JSX.Element => {
  return (
    <div className="investor-faq">
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Can I change my investor profile later?</Accordion.Header>
          <Accordion.Body>
            Yes, we encourage users to switch the investor profile later to fit their needs.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>How can I determine the investor profile?</Accordion.Header>
          <Accordion.Body>
            Yes, we encourage users to switch the investor profile later to fit their needs.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="mt-4">
        <ContactButtons small={window.innerWidth <= 576} />
      </div>
    </div>
  );
};
