import { useContext, useEffect, useState } from 'react';
import { DashboardContext, type IDashboardContext } from '../components/Dashboard/DashboardContext';
import { getPerformanceValues } from 'utils/getPerformanceValues';
import { type PortfolioPerformance } from 'types/investment';
import { useIndexTokenPrice } from './useIndexTokenPrice';
import { ethers } from 'ethers';

export const useGetPortfolioStatsOverview = () => {
  const { portfolio, setTotalPerformance, setPerformances } =
    useContext<IDashboardContext>(DashboardContext);
  const { getIndexTokenPrice } = useIndexTokenPrice();
  const [idp, setIdp] = useState<Record<string, number>>({});

  useEffect(() => {
    const fetchIndexTokenPrices = async () => {
      const prices: Record<string, number> = {};
      for (const item of portfolio.investments) {
        try {
          const price = await getIndexTokenPrice(item.bundle.name);
          if (price) {
            prices[item.bundle.name] = price;
          }
        } catch (error) {
          console.error('Error fetching index token price:', error);
        }
      }
      setIdp(prices);
    };
    fetchIndexTokenPrices();
  }, []);

  useEffect(() => {
    const calculateTotalPerformance = async () => {
      const indexTokenPrices = idp;
      let totalCurrentValue = ethers.BigNumber.from(0);
      let totalInitialValue = ethers.BigNumber.from(0);
      let totalBalancePerformance = ethers.BigNumber.from(0);
      const newPerformances: Record<string, PortfolioPerformance> = {};

      portfolio.investments.forEach((item) => {
        const performance = getPerformanceValues(item, indexTokenPrices);

        newPerformances[item.bundle.name] = performance;

        if (performance.balancePerformance !== 'N/A') {
          totalBalancePerformance = totalBalancePerformance.add(
            ethers.utils.parseUnits(performance.balancePerformance, 18),
          );
        }

        totalInitialValue = totalInitialValue.add(
          ethers.utils.parseUnits(item.initialInvestmentValue.toString(), 18),
        );

        const currentInvestmentValue = ethers.utils
          .parseUnits(item.totalIndexTokenBalance.toString(), 18)
          .mul(ethers.utils.parseUnits(indexTokenPrices[item.bundle.name].toString(), 18))
          .div(ethers.utils.parseUnits('1', 18));
        totalCurrentValue = totalCurrentValue.add(currentInvestmentValue);
      });

      let percentagePerformance = 'N/A';
      if (!totalInitialValue.isZero()) {
        const totalCurrentValueFloat = parseFloat(ethers.utils.formatUnits(totalCurrentValue, 18));
        const totalInitialValueFloat = parseFloat(ethers.utils.formatUnits(totalInitialValue, 18));
        percentagePerformance = (
          ((totalCurrentValueFloat - totalInitialValueFloat) / totalInitialValueFloat) *
          100
        ).toFixed(2);
      }

      setPerformances(newPerformances);

      setTotalPerformance({
        percentagePerformance,
        balancePerformance: ethers.utils.formatUnits(totalBalancePerformance, 18),
      });
    };

    calculateTotalPerformance();
  }, [portfolio.investments, getIndexTokenPrice, idp]);

  return {};
};
