import { useContext, useEffect, useState } from 'react';
import { DashboardContext, type IDashboardContext } from '../DashboardContext';
import { Modal, Button } from 'react-bootstrap';
import './DashboardCheckout.scss';
import 'components/LoadingSpinner.scss';
import { type CartItem, type Fees } from 'types';
import { useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/InfoTooltip';
import { SuccessTransaction } from 'components/SuccessTransaction';
import { useMutationIssueSetToken } from '../../../hooks/useMutationIssueSetToken';
import { useWeb3Auth } from '../../../services/web3auth';
import { useIndexTokenPrice } from '../../../hooks/useIndexTokenPrice';
import LoadingSpinner from '../../LoadingSpinner';

export const DashboardCheckout = (): JSX.Element => {
  const { t } = useTranslation('dashboard');

  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [subTotal, setSubTotal] = useState(0);
  const [fees, setFees] = useState<Fees>({
    serviceFee: 0,
    networkFee: 0,
    totalFees: 0,
  });
  const [cartItems, setCartItems] = useState<JSX.Element[]>([]);
  const [processedCartItems, setProcessedCartItems] = useState<CartItem[]>([]);
  const [openFees, setOpenFees] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [processingCheckout, setProcessingCheckout] = useState(false);
  const [showSuccessTransaction, setShowSuccessTransaction] = useState<boolean>(false);
  const [failedTxs, setFailedTxs] = useState<Record<string, string>>({});
  const [currentCartItem, setCurrentCartItem] = useState<CartItem>();

  const { provider, wb3athProvider } = useWeb3Auth();
  const web3AuthProviders = { provider, wb3athProvider };
  const { issueSetToken } = useMutationIssueSetToken(web3AuthProviders);
  const { getIndexTokenPrice } = useIndexTokenPrice();

  const returnCloseSuccesstransaction: () => void = () => {
    setShowSuccessTransaction(false);
  };

  const handleSubmit = async (): Promise<void> => {
    setProcessingCheckout(true);
    const newProcessedCartItems: CartItem[] = [];
    const failedTransactions: Record<string, string> = {};

    for (const item of dashboardContext.shoppingCartItems) {
      const indexTokenPrice = await getIndexTokenPrice(item.bundle.name);
      const result = await issueSetToken(item, indexTokenPrice);

      if (result?.success) {
        newProcessedCartItems.push(item);
      } else {
        failedTransactions[item.bundle.key] = result.error;
      }
    }

    setProcessedCartItems(newProcessedCartItems);
    setFailedTxs(failedTransactions);
    setProcessingCheckout(false);
    dashboardContext.setShoppingCartItems(
      dashboardContext.shoppingCartItems.filter((item) => !newProcessedCartItems.includes(item)),
    );
    setShowSuccessTransaction(Object.keys(failedTransactions).length === 0);
    if (Object.keys(failedTransactions).length === 0) {
      handleClose();
    }
  };

  const handleClose = (): void => {
    dashboardContext.setShowCheckout(false);
  };

  useEffect(() => {
    const getItems = (items: CartItem[]): JSX.Element[] => {
      const itemDivs = [];
      let runningTotal = 0;

      for (let loop = 0; loop < items.length; loop++) {
        const item = items[loop];
        setCurrentCartItem(item);
        runningTotal += item.investmentValue;
        itemDivs.push(
          <div key={loop} className="item-container">
            <div className="d-flex item-header">
              <div className="flex-grow-1">{item.bundle.name}</div>
              <div>
                {dashboardContext.currencyInfo.symbol}
                {(item.investmentValue * dashboardContext.currencyInfo.rate).toFixed(2)}
              </div>
            </div>
          </div>,
        );
      }
      const serviceFee = parseFloat((runningTotal * 0).toFixed(2));
      const networkFee = parseFloat(dashboardContext.txFees.toFixed(2));
      const totalFees = serviceFee + dashboardContext.txFees;
      setFees({
        serviceFee,
        networkFee,
        totalFees,
      });
      setSubTotal(runningTotal + serviceFee);
      return itemDivs;
    };

    setCartItems(getItems(dashboardContext.shoppingCartItems));
  }, [dashboardContext.shoppingCartItems, dashboardContext.txFees]);

  return (
    <>
      {showSuccessTransaction && (
        <SuccessTransaction
          key={currentCartItem?.bundle.key}
          close={returnCloseSuccesstransaction}
          withdraw={false}
          items={processedCartItems}
          failedTxs={failedTxs}
        />
      )}
      <Modal
        show={dashboardContext.showCheckout}
        onHide={handleClose}
        animation={false}
        className="checkout-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('checkout.investment')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex required-payment">
            <div className="flex-grow-1">{t('checkout.pay')}</div>
            <div>
              {dashboardContext.currencyInfo.symbol}
              {(subTotal * dashboardContext.currencyInfo.rate).toFixed(2)}
            </div>
          </div>
          <div className="bundles-label">{t('checkout.in')}</div>
          <div className="cart-items">{cartItems}</div>
          <div className="transaction-fees">
            <div
              className="fees-header d-flex"
              onClick={() => {
                setOpenFees(!openFees);
              }}
              aria-controls="example-collapse-text"
              aria-expanded={openFees}
            >
              <div className="flex-grow-1">{t('checkout.fees1')}</div>
              <div>
                {dashboardContext.currencyInfo.symbol}
                {(
                  (fees.serviceFee + dashboardContext.txFees) *
                  dashboardContext.currencyInfo.rate
                ).toFixed(2)}
                <InfoTooltip tooltip={t('checkout.content1')} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <div className="back-button">
              <Button
                variant="secondary"
                className="w-100 process-button cancel-button"
                onClick={handleClose}
                disabled={dashboardContext.shoppingCartItems?.length < 1 || processingCheckout}
              >
                {t('checkout.back')}
              </Button>
            </div>
            <div className="w-100">
              <Button
                variant="primary"
                className="w-100 process-button submit-button"
                onClick={handleSubmit}
                disabled={dashboardContext.shoppingCartItems?.length < 1 || processingCheckout}
              >
                {processingCheckout ? (
                  <div className="loading-spinner-button">
                    Processing
                    <LoadingSpinner />
                  </div>
                ) : (
                  t('checkout.submit')
                )}
              </Button>
            </div>
          </div>
          <div className="invest-terms">
            {t('checkout.warning1')}{' '}
            <span
              role="button"
              className="text-decoration-underline"
              onClick={() => {
                dashboardContext.setShowCheckout(false);
                setOpenTerms(true);
              }}
            >
              {t('checkout.warning2')}
            </span>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={openTerms} animation={false} centered className="terms-modal">
        <Modal.Header>
          <Modal.Title>{t('terms.header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('terms.content')}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="w-100 terms-confirm"
            onClick={() => {
              dashboardContext.setShowCheckout(true);
              setOpenTerms(false);
            }}
          >
            {t('terms.btn')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
